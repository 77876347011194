@import '../../assets/variables';

.courses__container {
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 20px;
  }
}

.courses__content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;

  .arrow__container {
    font-size: 3rem;
    position: absolute;
    top: -28px;
    right: 22px;
    color: $mediumGrey;
    z-index: 1;
  }

  .courses__item {
    height: 145px;
    width: 215px;
    margin: 5px;
    padding: 10px;
    list-style: none;
    background-color: $mediumGrey;
    border-radius: 10px;

    .courses__title,
    .courses__company {
      font-size: 1rem;
      margin: 18px 0px;
    }
    .courses__title {
      height: 40px;
      color: $darkGrey;
    }
    .courses__company {
      color: $lightGrey;

    }
    .button__text {
      margin-left: 10px;
      display: flex;
      justify-content: space-between;
      div {
        height: 40px;
        width: 40px;
        cursor: pointer;
        i.fa-download {
          text-decoration: none;
          color: $darkGrey;
        }
      }
    }
  }
}

