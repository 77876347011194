@import '../../assets/variables';

.projects__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 20px 40px 20px;
  background-color: $lightGrey;

  .projects__item {
    margin: 10px;
    &:hover {
      opacity: 0.7;
    }
    img {
      border-radius: 10px;
    }
  }
}
