@import '../../assets/variables';

.education__container {
  .education__item {
    padding: 5px 0px;
    .education__header {
      width: 97%;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      border-radius: 6px;
      border: none;
      border: 1px solid $mediumGrey2;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.1rem;
      cursor: pointer;
      &::after {
        content: "▼";
      }
      @media (max-width: 460px) {
        font-size: 1rem;
      }
      &:hover {
        background-color: $mediumGrey;
        color: $lightGrey
      }
      &:focus {
        border: none;
      }
    }
    .eduction__body {
      margin: 10px 30px 30px 30px;
      .education__company {
        font-size: 1.1rem;
        margin: 15px 0px;
      }
      .education__skills {
        margin-top: 15px;
      }
    }
  }
}

.hidden-class {
  display:none;
}
.rotate-class {
  transform: rotate(180deg);
}