@import '../../assets/variables';

.skills__container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .skills__content {
    width: 100%;
    margin: 0% 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
    @media (max-width: 530px) {
      margin: 3% 0%;
    }
    .skill__item {
      height: 60px;
      margin: 0px 10px;
      @media (max-width: 600px) {
        height: 50px;
      }
    }
    .skill__item-fa {
      font-size: 4rem;
      margin: 0px 10px;
      @media (max-width: 600px) {
        font-size: 3rem;
      }
    }
  }
}

.skills__container .skills__content {
  .fa-html5 {
    color: #e56027;
  }
  .fa-css3-alt {
    color: #04a1d9;
  }
  .fa-sass {
    color: #ca689b;
  }
  .fa-less {
    color: #1c3e69;
  }
  .fa-bootstrap {
    color: #563f7b;
  }
  .fa-js-square {
    color: #ead41c;
  }
  .fa-react {
    color: #4fb7d3;
  }
  .fa-angular {
    color: #d2002f;
  }
  .fa-wordpress {
    color: #499fc4;
  }
}