@import '../../assets/variables';

body {
  margin: 0;
  background-color: $colorWhite;
  color: $darkGrey;
}

.App {
  height: 100vh;
  position: relative;
  background-image: cover;
  background-size: cover;
  font-family: 'Montserrat', sans-serif;
  main {
    width: 100%;
    overflow-x: hidden;
  }
}
.othercourses__container {
  margin: 0px 50px;
  display: grid;
  padding-top: 50px;
  grid-template-columns: 25% 25% 25% 25%;
}
