$colorFabada: #fabada;

// Basic
$colorWhite: #ffffff;
$colorBlack: #000000;

// Grey
$lightGrey: #e0e0e0;
$mediumGrey2: #c2c0c0;
$mediumGrey: #838383;
$darkGrey: #2e2e2e;
$mediumGreyAlfa: #333333ab;

// Color
$colorPurpleLight: #ede6ff;
$colorPurpleMedium: #d1c1f8;
$colorPurpleDark: #6b657a;

